import React, {useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";

import {Button} from "../../common";
import CloseIcon from "../../assets/icons/edit-close.svg";
import DragDropIcon from "../../assets/icons/app-drag-drop.svg";
import {useAppDispatch, useAppSelector} from "../../util/hooks";

import {selectUser} from "../../auth/redux/authSlice";
import {creditClicked} from "../redux/farmSlice";

/* =============================================================================
<WelcomeDialog />
============================================================================= */
const WelcomeDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const user = useAppSelector(
    selectUser,
    (a, b) => a?.created_at === b?.created_at,
  );

  // Show dialog only on first login
  useEffect(() => {
    const showCreditPopup = !localStorage.getItem("@popup/credit");

    if (
      showCreditPopup &&
      user?.created_at &&
      new Date(user.created_at).getTime() > new Date("2025-01-01").getTime()
    ) {
      localStorage.setItem("@popup/credit", "true");
      dispatch(creditClicked());
      return;
    }

    const showGetStartedPopup = !localStorage.getItem("@popup/get_started");

    if (showGetStartedPopup) {
      localStorage.setItem("@popup/get_started", "true");
      setOpen(true);
      return;
    }
  }, [user, dispatch]);

  const _handleCloseClick = () => {
    setOpen(false);
  };

  const _handleLetsBeginClick = () => {
    setOpen(false);
  };

  const _handleLaterClick = () => {
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                <div className="relative p-12 text-center">
                  <button
                    type="button"
                    className="block absolute top-0 right-0 px-4 pt-3.5 pb-3"
                    onClick={_handleCloseClick}>
                    <CloseIcon width={21} height={21} />
                  </button>
                  <div className="flex justify-center">
                    <DragDropIcon />
                  </div>
                  <Dialog.Title as="h3" className="text-xl font-semibold mt-9">
                    Drag and drop a file
                  </Dialog.Title>
                  <p className="mt-3 text-sm font-medium text-gray-500">
                    to add a new field on your farm to begin the analysis (kml,
                    kmz or zipped shp folder)
                  </p>
                  <Button
                    type="button"
                    className="w-full mt-9"
                    onClick={_handleLetsBeginClick}>
                    Let’s begin!
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    className="w-full mt-4"
                    onClick={_handleLaterClick}>
                    Later
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

/* Export
============================================================================= */
export default WelcomeDialog;
