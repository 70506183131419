import {createSlice, type PayloadAction} from "@reduxjs/toolkit";

import {type RootState} from "../../redux/helpers";

// Initial state

interface User {
  id: string | number;
  email: string;
  username: string;
  lastName?: string | null;
  firstName?: string | null;
  isEmailVerified: boolean;
  created_at?: string | null;
}

interface AuthState {
  user: User | null;
}

const initialState: AuthState = {
  user: null,
};

// Slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loggedIn(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    loggedOut(state) {
      state.user = null;
    },
    emailVerified(state) {
      if (state.user) {
        state.user.isEmailVerified = true;
      }
    },
  },
});

// Actions
export const {loggedIn, loggedOut, emailVerified} = authSlice.actions;

// Reducer
export default authSlice.reducer;

// Selectors

export const selectUser = (state: RootState) => state.auth?.user ?? null;

export const selectIsAuthenticated = (state: RootState) =>
  !!selectUser(state)?.isEmailVerified;
