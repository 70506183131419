import React, {useState, useEffect} from "react";

import BackgroundImage1 from "../../../assets/images/auth-background-1.webp";
import BackgroundImage2 from "../../../assets/images/auth-background-2.webp";

/* =============================================================================
<AuthLayoutBackground />
============================================================================= */
const AuthLayoutBackground: React.FC = () => {
  const [isFading, setIsFading] = useState(false);
  const [currentBgIndex, setCurrentBgIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true);

      setTimeout(() => {
        setCurrentBgIndex(prevIndex => (prevIndex + 1) % BACKGROUNDS.length);

        setIsFading(false);
      }, 500);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={styles.container}
      className={`absolute top-0 left-0 right-0 bottom-0 overflow-hidden bg-[url('/src/assets/images/auth-background.webp')] bg-cover bg-no-repeat`}>
      <img
        src={BACKGROUNDS[currentBgIndex]}
        width="100%"
        height="100%"
        className={`transition-opacity ease-in duration-500 ${isFading ? "opacity-0" : "opacity-100"}`}
      />
    </div>
  );
};

const BACKGROUNDS = [BackgroundImage1, BackgroundImage2];

const styles = {
  container: {
    zIndex: -1,
  },
};

/* Export
============================================================================= */
export default AuthLayoutBackground;
