import {createSlice} from "@reduxjs/toolkit";

import {type RootState} from "../../redux/helpers";

// Initial state

interface MapState {
  type: "map" | "satellite";
}

const initialState: MapState = {
  type: "satellite",
};

// Slice
const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    mapTypeSelected(state) {
      state.type = "map";
    },
    satelliteTypeSelected(state) {
      state.type = "satellite";
    },
  },
});

// Actions
export const {mapTypeSelected, satelliteTypeSelected} = mapSlice.actions;

// Reducer
export default mapSlice.reducer;

// Selectors

export const selectType = (state: RootState) => state.map?.type ?? "";
