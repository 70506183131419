import React, {
  useState,
  useCallback,
  type FormEvent,
  type ChangeEvent,
} from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useLazyQuery, useMutation} from "@apollo/client";

import {Button, ErrorMessage, TextInput} from "../../common";
import AuthLayout from "../components/AuthLayout";
import AppLogo from "../../assets/icons/app-logo.svg";
// import GoogleLogo from "../../assets/icons/brand-google.png";
import {errorMessage} from "../../util/functions";
import {useAppDispatch} from "../../util/hooks";

import {REGISTER} from "../api/mutations";
import {GET_USER} from "../api/queries";

import {loggedIn} from "../redux/authSlice";

/* =============================================================================
<RegisterScreen />
============================================================================= */
const RegisterScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [register, {loading: registerLoading, error: registerError}] =
    useMutation(REGISTER);
  const [getUser, {loading: getUserLoading, error: getUserError}] =
    useLazyQuery(GET_USER);

  const error = registerError || getUserError;
  const loading = registerLoading || getUserLoading;

  const _handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const _handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const _handleEmailChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    [],
  );

  const _handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const _handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (firstName && lastName && email && password && !loading) {
      const registerResponse = await register({
        variables: {
          input: {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            role: "farmer",
          },
        },
      });

      if (registerResponse.data?.api_sign_up_user) {
        localStorage.setItem(
          "@auth/token",
          `${registerResponse.data.api_sign_up_user?.token}`,
        );

        const getUserResponse = await getUser({
          variables: {
            id: registerResponse.data.api_sign_up_user.id,
          },
        });

        // Remove welcome popups state on register
        localStorage.removeItem("@popup/credit");
        localStorage.removeItem("@popup/get_started");

        if (getUserResponse.data?.users_by_pk) {
          dispatch(loggedIn(getUserResponse.data.users_by_pk));
        }
      }
    }
  };

  return (
    <div id="register" className="w-full h-full">
      <Helmet>
        <title>Sign up | Lense AI</title>
      </Helmet>
      <AuthLayout>
        <div className="w-full h-full pt-16 px-16 pb-24">
          <div className="w-full h-full md:w-[330px] flex flex-col justify-between ml-auto p-5 rounded-2xl overflow-y-auto bg-white">
            <div className="flex flex-col items-center mt-10">
              <AppLogo width={38} height={38} fill="#FF7E1D" />
              <h1 className="text-[28px] leading-[45px] font-semibold mt-4 text-center">
                Sign Up
              </h1>
              <p className="mt-2 text-center text-sm leading-7">
                Already have an account?{" "}
                <Link to="/login" className="underline text-orange-500">
                  Log in
                </Link>
              </p>
            </div>
            {/* <button className="w-full flex items-center justify-center py-3.5 font-medium mt-16 rounded-md border borer-[#CDD5DD] pointer-events-none">
              <img
                src={GoogleLogo}
                alt="Google Logo"
                width={23}
                height={23}
                className="mr-3.5"
              />{" "}
              Sign up with Google
            </button> */}
            <form className="w-full mt-[30px]" onSubmit={_handleSubmit}>
              <div className="grid grid-cols-1 gap-y-5">
                <TextInput
                  id="firstName"
                  type="text"
                  name="firstName"
                  value={firstName}
                  placeholder="First Name*"
                  onChange={_handleFirstNameChange}
                />
                <TextInput
                  id="lastName"
                  type="text"
                  name="lastName"
                  value={lastName}
                  placeholder="Last Name*"
                  onChange={_handleLastNameChange}
                />
                <TextInput
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  autoComplete="email"
                  placeholder="Email address*"
                  onChange={_handleEmailChange}
                />
                <TextInput
                  id="password"
                  type="password"
                  name="password"
                  value={password}
                  autoComplete="organization"
                  placeholder="Password*"
                  onChange={_handlePasswordChange}
                />
                <Button
                  type="submit"
                  size="lg"
                  className="mt-6"
                  loading={loading}>
                  Create account
                </Button>
                {error && (
                  <ErrorMessage message={errorMessage(error.message)} />
                )}
              </div>
            </form>
          </div>
        </div>
      </AuthLayout>
    </div>
  );
};

/* Export
============================================================================= */
export default RegisterScreen;
