import {gql} from "../../__generated__/gql";

export const LOGIN = gql(/* GraphQL */ `
  query Login($input: api_login_user_input) {
    api_login_user(input: $input) {
      token
      id
    }
  }
`);

export const GET_USER = gql(/* GraphQL */ `
  query GetUser($id: Int!) {
    users_by_pk(id: $id) {
      id
      email
      username
      firstName
      lastName
      isEmailVerified
      created_at
    }
  }
`);
