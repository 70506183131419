import axios, {
  type AxiosError,
  type AxiosResponse,
  type AxiosRequestConfig,
} from "axios";

import {API_URL} from "../config/secrets";

/**
 * Request Wrapper with default success/error actions
 */
const request = async (options: AxiosRequestConfig, baseURL = API_URL) => {
  const token = localStorage.getItem("@auth/token");

  const client = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const onSuccess = (response: AxiosResponse) => {
    return response;
  };

  const onError = (error: AxiosError) => {
    // console.debug('Request Failed:', error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      // console.debug('Status:', error.response.status);
      // console.debug('Data:', error.response.data);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      // console.debug('Error Message:', error.message);
    }

    return Promise.reject(error.response ? error.response.data : error);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
