import React, {useCallback, useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {shallowEqual} from "react-redux";
import {Menu, Transition} from "@headlessui/react";

import MapPinIcon from "../../../assets/icons/map-pin.svg";
import ArrowDownIcon from "../../../assets/icons/edit-arrow-down.svg";

import {GET_FARMS} from "../../api/queries";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import {farmSelected, selectSelectedFarm} from "../../redux/farmSlice";

/* =============================================================================
<SelectFarm />
============================================================================= */
const SelectFarm: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedFarm = useAppSelector(selectSelectedFarm, shallowEqual);
  const [options, setOptions] = useState<Array<{label: string; value: string}>>(
    [],
  );
  const {data} = useQuery(GET_FARMS);

  const selectedOption = options.find(
    option => `${option.value}` === `${selectedFarm}`,
  );

  // Set options
  useEffect(() => {
    if (data?.farms) {
      setOptions(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        data.farms?.map(farm => ({
          label: farm.name,
          value: farm.id,
        })),
      );
    }
  }, [data?.farms]);

  const _handleChange = useCallback(
    (value: string | number) => {
      dispatch(farmSelected(value));
    },
    [dispatch],
  );

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="w-52 h-9 relative flex items-center px-4 rounded-lg bg-white app-shadow">
            <MapPinIcon />
            <span className="block flex-1 px-2 font-medium text-sm text-left capitalize truncate">
              {selectedOption ? selectedOption.label : "Select Farm"}
            </span>
            <ArrowDownIcon />
          </Menu.Button>
        </div>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="max-h-96 absolute right-0 mt-2 w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white overflow-auto shadow-lg ring-1 ring-black/5 focus:outline-none">
            {options.length > 0 ? (
              options.map(option => (
                <Menu.Item key={option.value}>
                  {({active}) => (
                    <button
                      className={`${
                        active ? "bg-black text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-3 py-3 text-sm`}
                      onClick={() => _handleChange(option.value)}>
                      {option.label}
                    </button>
                  )}
                </Menu.Item>
              ))
            ) : (
              <Menu.Item>
                {({active}) => (
                  <button
                    className={`${
                      active ? "bg-black text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-3 py-3 text-sm`}>
                    No farms yet
                  </button>
                )}
              </Menu.Item>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

/* Export
============================================================================= */
export default SelectFarm;
