import React, {type PropsWithChildren} from "react";
import {shallowEqual} from "react-redux";
import {Navigate, useLocation} from "react-router-dom";

import {useAppSelector} from "../util/hooks";

import {selectIsAuthenticated, selectUser} from "../auth/redux/authSlice";

/* =============================================================================
<RequireNoAuth />
============================================================================= */
const RequireNoAuth: React.FC<PropsWithChildren> = ({children}) => {
  const location = useLocation();
  const user = useAppSelector(selectUser, (a, b) => Boolean(a) === Boolean(b));
  const isAuthenticated = useAppSelector(selectIsAuthenticated, shallowEqual);

  if (isAuthenticated) {
    const from = location.state?.from?.pathname || "/";

    return <Navigate to={from} replace />;
  }

  if (user) {
    return <Navigate to="/verification" replace />;
  }

  return children;
};

/* Export
============================================================================= */
export default RequireNoAuth;
