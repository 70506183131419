import Layer from "ol/layer/Layer.js";
import WebGLVectorLayerRenderer from "ol/renderer/webgl/VectorLayer.js";
import type VectorSource from "ol/source/Vector";

class WebGlLayer extends Layer {
  createRenderer() {
    let strokeWidth: number = 0;

    const source = this.getSource() as VectorSource;
    if (source) {
      const features = source.getFeatures();
      if (features.length > 0) {
        const firstFeature = features[0];
        const geometry = firstFeature.getGeometry();
        const geometryType = geometry ? geometry.getType() : "No Geometry";

        if (
          geometryType === "MultiLineString" ||
          geometryType === "LineString"
        ) {
          strokeWidth = 1.5;
        } else {
          strokeWidth = 0;
        }
      } else {
        console.log("No features in the source");
      }
    }
    const style = {
      "stroke-color": [
        "case",
        ["==", ["get", "color"], "E3412B"],
        "#E3412B",
        ["==", ["get", "color"], "FEB743"],
        "#FEB743",
        ["==", ["get", "color"], "30C876"],
        "#30C876",
        ["==", ["get", "color"], "1E8C4D"],
        "#1E8C4D",
        ["==", ["get", "color"], "828282"],
        "#828282",
        ["==", ["get", "class"], "0"],
        "#E3412B",
        ["==", ["get", "class"], "1"],
        "#FEB743",
        ["==", ["get", "class"], "2"],
        "#30C876",
        ["==", ["get", "class"], "3"],
        "#1E8C4D",
        ["==", ["get", "class"], "4"],
        "#828282",
        ["==", ["get", "class"], "5"],
        "#E6F0D0",
        ["==", ["get", "class"], "6"],
        "#9BC4C1",
        ["==", ["get", "class"], "7"],
        "#4B8098",
        ["==", ["get", "class"], "8"],
        "#2D577C",
        ["==", ["get", "class"], "saline_soil"],
        "rgba(171, 23, 23, 1)",
        ["==", ["get", "class"], "waterlog"],
        "rgba(129, 214, 238, 1)",
        "rgba(255, 0, 64, 1)",
      ],
      "stroke-width": strokeWidth,
      "fill-color": [
        "case",
        ["==", ["get", "color"], "E3412B"],
        "rgba(227, 65, 43, 0.3)",
        ["==", ["get", "color"], "FEB743"],
        "rgba(254, 183, 67, 0.3)",
        ["==", ["get", "color"], "30C876"],
        "rgba(48, 200, 118, 0.3)",
        ["==", ["get", "color"], "1E8C4D"],
        "rgba(30, 140, 77, 0.3)",
        ["==", ["get", "color"], "828282"],
        "rgba(130, 130, 130, 0.3)",
        ["==", ["get", "class"], "0"],
        "rgba(227, 65, 43, 0.3)",
        ["==", ["get", "class"], "1"],
        "rgba(254, 183, 67, 0.3)",
        ["==", ["get", "class"], "2"],
        "rgba(48, 200, 118, 0.3)",
        ["==", ["get", "class"], "3"],
        "rgba(30, 140, 77, 0.3)",
        ["==", ["get", "class"], "4"],
        "rgba(130, 130, 130, 0.3)",
        ["==", ["get", "class"], "5"],
        "rgba(230, 240, 208, 0.4)",
        ["==", ["get", "class"], "6"],
        "rgba(155, 196, 193, 0.4)",
        ["==", ["get", "class"], "7"],
        "rgba(75, 128, 152, 0.4)",
        ["==", ["get", "class"], "8"],
        "rgba(45, 87, 124, 0.4)",
        ["==", ["get", "class"], "saline_soil"],
        "rgba(171, 23, 23, 0.3)",
        ["==", ["get", "class"], "waterlog"],
        "rgba(129, 214, 238, 0.5)",
        "rgba(255, 0, 64, 0.25)",
      ],
    };
    return new WebGLVectorLayerRenderer(this, {
      style,
    });
  }
}

export default WebGlLayer;
