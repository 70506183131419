/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation Register($input: api_sign_up_user_input) {\n    api_sign_up_user(input: $input) {\n      token\n      id\n    }\n  }\n": types.RegisterDocument,
    "\n  query Login($input: api_login_user_input) {\n    api_login_user(input: $input) {\n      token\n      id\n    }\n  }\n": types.LoginDocument,
    "\n  query GetUser($id: Int!) {\n    users_by_pk(id: $id) {\n      id\n      email\n      username\n      firstName\n      lastName\n      isEmailVerified\n      created_at\n    }\n  }\n": types.GetUserDocument,
    "\n  mutation InsertFieldMapAnnotation(\n    $input: high_res_map_annotations_insert_input!\n  ) {\n    insert_high_res_map_annotations_one(object: $input) {\n      id\n    }\n  }\n": types.InsertFieldMapAnnotationDocument,
    "\n  mutation UpdateFieldMapAnnotation(\n    $pk_columns_input: high_res_map_annotations_pk_columns_input!\n    $set_input: high_res_map_annotations_set_input\n  ) {\n    update_high_res_map_annotations_by_pk(\n      pk_columns: $pk_columns_input\n      _set: $set_input\n    ) {\n      id\n    }\n  }\n": types.UpdateFieldMapAnnotationDocument,
    "\n  mutation DeleteFieldMapAnnotation($id: bigint!) {\n    delete_high_res_map_annotations_by_pk(id: $id) {\n      id\n    }\n  }\n": types.DeleteFieldMapAnnotationDocument,
    "\n  query GetHighResMap($id: bigint!) {\n    high_res_maps_by_pk(id: $id) {\n      id\n      name\n      link\n      ms_link\n      ms_rapid_link\n      rgb_link\n      rgb_rapid_link\n      captured_at\n      createdAt\n    }\n  }\n": types.GetHighResMapDocument,
    "\n  query GetFieldMapLayers($where: high_res_map_layers_bool_exp) {\n    high_res_map_layers(where: $where) {\n      id\n      name\n      link\n      createdAt\n    }\n  }\n": types.GetFieldMapLayersDocument,
    "\n  query GetFieldMapLayer($id: bigint!) {\n    high_res_map_layers_by_pk(id: $id) {\n      id\n      name\n      link\n      createdAt\n    }\n  }\n": types.GetFieldMapLayerDocument,
    "\n  query GetFieldMapAnnotations($where: high_res_map_annotations_bool_exp) {\n    high_res_map_annotations(where: $where) {\n      id\n      name\n      area\n      geometry\n      description\n      createdAt\n    }\n  }\n": types.GetFieldMapAnnotationsDocument,
    "\n  query GetFieldMapAnnotation($id: bigint!) {\n    high_res_map_annotations_by_pk(id: $id) {\n      id\n      name\n      area\n      geometry\n      description\n      createdAt\n    }\n  }\n": types.GetFieldMapAnnotationDocument,
    "\n  mutation InsertFarm($input: farms_insert_input!) {\n    insert_farms_one(object: $input) {\n      id\n    }\n  }\n": types.InsertFarmDocument,
    "\n  mutation InsertFieldSeasonShot($input: field_season_shot_insert_input!) {\n    insert_field_season_shot_one(object: $input) {\n      id\n      fieldId\n    }\n  }\n": types.InsertFieldSeasonShotDocument,
    "\n  query GetFarms {\n    farms {\n      id\n      name\n    }\n  }\n": types.GetFarmsDocument,
    "\n  query GetFields($where: field_season_shot_bool_exp) {\n    field_season_shot(where: $where, distinct_on: fieldId) {\n      id\n      name\n      cropName\n      polygon {\n        geometry\n        geometry_wkt\n      }\n    }\n  }\n": types.GetFieldsDocument,
    "\n  query GetField($id: bigint!) {\n    field_season_shot_by_pk(id: $id) {\n      id\n      name\n      season\n      cropName\n      polygon {\n        size\n        geometry\n        geometry_wkt\n      }\n    }\n  }\n": types.GetFieldDocument,
    "\n  query GetFieldMaps($where: high_res_maps_bool_exp) {\n    high_res_maps(where: $where) {\n      id\n      name\n      link\n      ms_link\n      ms_rapid_link\n      rgb_link\n      rgb_rapid_link\n      captured_at\n      createdAt\n    }\n  }\n": types.GetFieldMapsDocument,
    "\n  query GetFieldReports($where: reports_bool_exp) {\n    reports(where: $where) {\n      id\n      name\n      createdAt\n      link\n    }\n  }\n": types.GetFieldReportsDocument,
    "\n  query GetFarmName($where: farms_bool_exp) {\n    farms(where: $where) {\n      id\n      name\n    }\n  }\n": types.GetFarmNameDocument,
    "\n      query GetUserFields {\n        field_season_shot(\n          where: {farm: {userId: {_eq: \"\"}}}\n          distinct_on: fieldId\n        ) {\n          id\n          name\n          farmId\n          cropName\n          polygon {\n            geometry\n            geometry_wkt\n          }\n        }\n      }\n    ": types.GetUserFieldsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Register($input: api_sign_up_user_input) {\n    api_sign_up_user(input: $input) {\n      token\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation Register($input: api_sign_up_user_input) {\n    api_sign_up_user(input: $input) {\n      token\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Login($input: api_login_user_input) {\n    api_login_user(input: $input) {\n      token\n      id\n    }\n  }\n"): (typeof documents)["\n  query Login($input: api_login_user_input) {\n    api_login_user(input: $input) {\n      token\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetUser($id: Int!) {\n    users_by_pk(id: $id) {\n      id\n      email\n      username\n      firstName\n      lastName\n      isEmailVerified\n      created_at\n    }\n  }\n"): (typeof documents)["\n  query GetUser($id: Int!) {\n    users_by_pk(id: $id) {\n      id\n      email\n      username\n      firstName\n      lastName\n      isEmailVerified\n      created_at\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation InsertFieldMapAnnotation(\n    $input: high_res_map_annotations_insert_input!\n  ) {\n    insert_high_res_map_annotations_one(object: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation InsertFieldMapAnnotation(\n    $input: high_res_map_annotations_insert_input!\n  ) {\n    insert_high_res_map_annotations_one(object: $input) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateFieldMapAnnotation(\n    $pk_columns_input: high_res_map_annotations_pk_columns_input!\n    $set_input: high_res_map_annotations_set_input\n  ) {\n    update_high_res_map_annotations_by_pk(\n      pk_columns: $pk_columns_input\n      _set: $set_input\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateFieldMapAnnotation(\n    $pk_columns_input: high_res_map_annotations_pk_columns_input!\n    $set_input: high_res_map_annotations_set_input\n  ) {\n    update_high_res_map_annotations_by_pk(\n      pk_columns: $pk_columns_input\n      _set: $set_input\n    ) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteFieldMapAnnotation($id: bigint!) {\n    delete_high_res_map_annotations_by_pk(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteFieldMapAnnotation($id: bigint!) {\n    delete_high_res_map_annotations_by_pk(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetHighResMap($id: bigint!) {\n    high_res_maps_by_pk(id: $id) {\n      id\n      name\n      link\n      ms_link\n      ms_rapid_link\n      rgb_link\n      rgb_rapid_link\n      captured_at\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query GetHighResMap($id: bigint!) {\n    high_res_maps_by_pk(id: $id) {\n      id\n      name\n      link\n      ms_link\n      ms_rapid_link\n      rgb_link\n      rgb_rapid_link\n      captured_at\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFieldMapLayers($where: high_res_map_layers_bool_exp) {\n    high_res_map_layers(where: $where) {\n      id\n      name\n      link\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query GetFieldMapLayers($where: high_res_map_layers_bool_exp) {\n    high_res_map_layers(where: $where) {\n      id\n      name\n      link\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFieldMapLayer($id: bigint!) {\n    high_res_map_layers_by_pk(id: $id) {\n      id\n      name\n      link\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query GetFieldMapLayer($id: bigint!) {\n    high_res_map_layers_by_pk(id: $id) {\n      id\n      name\n      link\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFieldMapAnnotations($where: high_res_map_annotations_bool_exp) {\n    high_res_map_annotations(where: $where) {\n      id\n      name\n      area\n      geometry\n      description\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query GetFieldMapAnnotations($where: high_res_map_annotations_bool_exp) {\n    high_res_map_annotations(where: $where) {\n      id\n      name\n      area\n      geometry\n      description\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFieldMapAnnotation($id: bigint!) {\n    high_res_map_annotations_by_pk(id: $id) {\n      id\n      name\n      area\n      geometry\n      description\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query GetFieldMapAnnotation($id: bigint!) {\n    high_res_map_annotations_by_pk(id: $id) {\n      id\n      name\n      area\n      geometry\n      description\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation InsertFarm($input: farms_insert_input!) {\n    insert_farms_one(object: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation InsertFarm($input: farms_insert_input!) {\n    insert_farms_one(object: $input) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation InsertFieldSeasonShot($input: field_season_shot_insert_input!) {\n    insert_field_season_shot_one(object: $input) {\n      id\n      fieldId\n    }\n  }\n"): (typeof documents)["\n  mutation InsertFieldSeasonShot($input: field_season_shot_insert_input!) {\n    insert_field_season_shot_one(object: $input) {\n      id\n      fieldId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFarms {\n    farms {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query GetFarms {\n    farms {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFields($where: field_season_shot_bool_exp) {\n    field_season_shot(where: $where, distinct_on: fieldId) {\n      id\n      name\n      cropName\n      polygon {\n        geometry\n        geometry_wkt\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetFields($where: field_season_shot_bool_exp) {\n    field_season_shot(where: $where, distinct_on: fieldId) {\n      id\n      name\n      cropName\n      polygon {\n        geometry\n        geometry_wkt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetField($id: bigint!) {\n    field_season_shot_by_pk(id: $id) {\n      id\n      name\n      season\n      cropName\n      polygon {\n        size\n        geometry\n        geometry_wkt\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetField($id: bigint!) {\n    field_season_shot_by_pk(id: $id) {\n      id\n      name\n      season\n      cropName\n      polygon {\n        size\n        geometry\n        geometry_wkt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFieldMaps($where: high_res_maps_bool_exp) {\n    high_res_maps(where: $where) {\n      id\n      name\n      link\n      ms_link\n      ms_rapid_link\n      rgb_link\n      rgb_rapid_link\n      captured_at\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query GetFieldMaps($where: high_res_maps_bool_exp) {\n    high_res_maps(where: $where) {\n      id\n      name\n      link\n      ms_link\n      ms_rapid_link\n      rgb_link\n      rgb_rapid_link\n      captured_at\n      createdAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFieldReports($where: reports_bool_exp) {\n    reports(where: $where) {\n      id\n      name\n      createdAt\n      link\n    }\n  }\n"): (typeof documents)["\n  query GetFieldReports($where: reports_bool_exp) {\n    reports(where: $where) {\n      id\n      name\n      createdAt\n      link\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFarmName($where: farms_bool_exp) {\n    farms(where: $where) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query GetFarmName($where: farms_bool_exp) {\n    farms(where: $where) {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query GetUserFields {\n        field_season_shot(\n          where: {farm: {userId: {_eq: \"\"}}}\n          distinct_on: fieldId\n        ) {\n          id\n          name\n          farmId\n          cropName\n          polygon {\n            geometry\n            geometry_wkt\n          }\n        }\n      }\n    "): (typeof documents)["\n      query GetUserFields {\n        field_season_shot(\n          where: {farm: {userId: {_eq: \"\"}}}\n          distinct_on: fieldId\n        ) {\n          id\n          name\n          farmId\n          cropName\n          polygon {\n            geometry\n            geometry_wkt\n          }\n        }\n      }\n    "];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;