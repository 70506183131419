import React from "react";
import {Menu, Transition} from "@headlessui/react";

import PlusIcon from "../../../assets/icons/edit-plus.svg";
import {useAppDispatch} from "../../../util/hooks";

import {toolSelected} from "../../redux/addFieldSlice";
import {addFieldSelected} from "../../redux/farmSlice";

/* =============================================================================
<AddFieldButton />
============================================================================= */
const AddFieldButton: React.FC = () => {
  const dispatch = useAppDispatch();

  const _handleChange = (tool: "Polygon" | "Circle") => {
    dispatch(addFieldSelected());
    dispatch(toolSelected(tool));
  };

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="w-[8rem] h-9 relative flex items-center px-4 rounded-lg bg-white app-shadow">
            <PlusIcon />
            <span className="ml-2 font-medium text-sm">Add Field</span>
          </Menu.Button>
        </div>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="max-h-96 absolute right-0 mt-2 w-[8rem] origin-top-right divide-y divide-gray-100 rounded-md bg-white overflow-auto shadow-lg ring-1 ring-black/5 focus:outline-none">
            <Menu.Item>
              <button
                className={`${"text-gray-900"} group flex w-full items-center rounded-md px-3 py-3 text-sm`}
                onClick={() => _handleChange("Polygon")}>
                Polygon
              </button>
            </Menu.Item>
            <Menu.Item>
              <button
                className={`${"text-gray-900"} group flex w-full items-center rounded-md px-3 py-3 text-sm`}
                onClick={() => _handleChange("Circle")}>
                Circle
              </button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

/* Export
============================================================================= */
export default AddFieldButton;
