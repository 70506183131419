import React, {forwardRef} from "react";
import {cls} from "../util/functions";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}

/* =============================================================================
<TextInput />
============================================================================= */
const TextInput = forwardRef<HTMLInputElement, Props>(
  ({label, className, labelProps, containerProps, ...rest}, ref) => {
    return (
      <div
        {...containerProps}
        className={cls(`
        ${classes.container.base}
        ${containerProps?.className}
      `)}>
        {label && (
          <label
            {...labelProps}
            className={cls(`
            ${classes.label.base}
            ${labelProps?.className}
          `)}>
            {label}
          </label>
        )}
        <input
          ref={ref}
          className={cls(`
            ${classes.input.base}
            ${className}
          `)}
          {...rest}
        />
      </div>
    );
  },
);

const classes = {
  container: {
    base: "w-full",
  },
  label: {
    base: "block font-medium text-black text-sm mb-3",
  },
  input: {
    base: "block w-full px-3 py-4 rounded-md border-0 ring-1 ring-inset ring-[#CDD5DD] text-black text-sm shadow-sm placeholder:text-[#ACB6C0] focus:ring-2 focus:ring-inset",
  },
};

TextInput.displayName = "TextInput";

/* Export
============================================================================= */
export default TextInput;
