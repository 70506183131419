import React from "react";
import {useAppDispatch, useAppSelector} from "../../../util/hooks";

import CreditCardIcon from "../../../assets/icons/app-credit-card.svg";

import {selectUser} from "../../../auth/redux/authSlice";
import {creditClicked} from "../../redux/farmSlice";

/* =============================================================================
<CreditButton />
============================================================================= */
const CreditButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(
    selectUser,
    (a, b) => a?.created_at === b?.created_at,
  );

  if (
    user?.created_at &&
    new Date(user.created_at).getTime() > new Date("2025-01-01").getTime()
  ) {
    const credits = 200;

    const _handleClick = () => {
      dispatch(creditClicked());
    };

    return (
      <button
        type="button"
        className="h-9 flex items-center px-4 rounded-lg bg-white app-shadow"
        onClick={_handleClick}>
        <CreditCardIcon />
        <span className="ml-2 font-medium text-sm">{`$${credits}`}</span>
      </button>
    );
  }

  return null;
};

/* Export
============================================================================= */
export default CreditButton;
