import {createSlice, type PayloadAction} from "@reduxjs/toolkit";

import {type RootState} from "../../redux/helpers";

// Initial state

interface FarmState {
  action:
    | "credit-popup"
    | "field-details"
    | "process-data"
    | "upload"
    | "add-farm"
    | "add-field"
    | "notifications"
    | "generate-reports"
    | "generate-mission-files"
    | null;
  selectedFarm: string | number | null;
  selectedField: string | number | null;
}

const initialState: FarmState = {
  action: null,
  selectedFarm: null,
  selectedField: null,
};

// Slice
const farmSlice = createSlice({
  name: "farm",
  initialState,
  reducers: {
    actionClosed(state) {
      if (state.action === "field-details") {
        // Unselect field and close action
        state.selectedField = null;
        state.action = null;
      } else if (state.selectedField) {
        // Go back to field details
        state.action = "field-details";
      } else {
        // Close action
        state.action = null;
      }
    },
    farmSelected(state, action: PayloadAction<string | number>) {
      if (state.selectedFarm !== action.payload) {
        // Unselect field
        state.selectedField = null;

        // Close field view
        if (state.action === "field-details") {
          state.action = null;
        }
      }

      state.selectedFarm = action.payload;
    },
    fieldSelected(state, action: PayloadAction<string | number>) {
      state.action = "field-details";
      state.selectedField = action.payload;
    },
    creditClicked(state) {
      state.action = "credit-popup";
    },
    uploadSelected(state) {
      state.action = "upload";
    },
    addFarmSelected(state) {
      state.action = "add-farm";
    },
    addFieldSelected(state) {
      state.action = "add-field";
    },
    processDataSelected(state) {
      state.action = "process-data";
    },
    notificationsSelected(state) {
      state.action = "notifications";
    },
    generateReportsSelected(state) {
      state.action = "generate-reports";
    },
    generateMissionFilesSelected(state) {
      state.action = "generate-mission-files";
    },
  },
});

// Actions
export const {
  actionClosed,
  farmSelected,
  fieldSelected,
  creditClicked,
  uploadSelected,
  addFarmSelected,
  addFieldSelected,
  processDataSelected,
  notificationsSelected,
  generateReportsSelected,
  generateMissionFilesSelected,
} = farmSlice.actions;

// Reducer
export default farmSlice.reducer;

// Selectors

export const selectAction = (state: RootState) => state.farm?.action ?? null;

export const selectSelectedFarm = (state: RootState) =>
  state.farm?.selectedFarm ?? null;

export const selectSelectedField = (state: RootState) =>
  state.farm?.selectedField ?? null;
