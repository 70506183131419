import React from "react";

import {Button} from "../../common";
import TrophyIcon from "../../assets/icons/app-trophy.svg";
import {useAppDispatch} from "../../util/hooks";

import {actionClosed} from "../redux/farmSlice";

/* =============================================================================
<CreditPopup />
============================================================================= */
const CreditPopup: React.FC = () => {
  const dispatch = useAppDispatch();

  const credit = 200;

  const _handleClaimNow = () => {
    dispatch(actionClosed());
  };

  return (
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white z-10 app-shadow">
      <div className="w-[437px] px-11 py-9 text-center">
        <div className="flex justify-center">
          <TrophyIcon width={51} height={51} />
        </div>
        <h2 className="text-xl font-semibold mt-9">Claim your</h2>
        <h1 className="text-[40px] font-normal mt-1">{`$${credit} Credit`}</h1>
        <p className="text-sm text-[#536471] font-medium mt-2">
          Get instant analysis of your fields in just few clicks with Lense AI
        </p>
        <Button
          type="submit"
          variant="primary"
          className="w-full mt-9"
          onClick={_handleClaimNow}>
          Claim now
        </Button>
      </div>
    </div>
  );
};

/* Export
============================================================================= */
export default CreditPopup;
