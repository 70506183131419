import React, {
  useState,
  useCallback,
  type FormEvent,
  type ChangeEvent,
} from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useLazyQuery} from "@apollo/client";

import {Button, ErrorMessage, TextInput} from "../../common";
import AuthLayout from "../components/AuthLayout";
import AppLogo from "../../assets/icons/app-logo.svg";
// import GoogleLogo from "../../assets/icons/brand-google.png";
import {errorMessage} from "../../util/functions";
import {useAppDispatch} from "../../util/hooks";

import {GET_USER, LOGIN} from "../api/queries";
import {loggedIn} from "../redux/authSlice";

/* =============================================================================
<LoginScreen />
============================================================================= */
const LoginScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, {loading: loginLoading, error: loginError}] = useLazyQuery(
    LOGIN,
    {
      fetchPolicy: "no-cache",
    },
  );
  const [getUser, {loading: getUserLoading, error: getUserError}] =
    useLazyQuery(GET_USER);

  const error = loginError || getUserError;
  const loading = loginLoading || getUserLoading;

  const _handleEmailChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    [],
  );

  const _handlePasswordChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    [],
  );

  const _handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (email && password && !loading) {
      const loginResponse = await login({
        variables: {
          input: {
            username: email,
            password,
          },
        },
      });

      if (loginResponse.data?.api_login_user) {
        localStorage.setItem(
          "@auth/token",
          `${loginResponse.data.api_login_user?.token}`,
        );

        const getUserResponse = await getUser({
          variables: {
            id: loginResponse.data.api_login_user.id,
          },
        });

        if (getUserResponse.data?.users_by_pk) {
          dispatch(loggedIn(getUserResponse.data.users_by_pk));
        }
      }
    }
  };

  return (
    <div id="login" className="w-full h-full">
      <Helmet>
        <title>Login | Lense AI</title>
      </Helmet>
      <AuthLayout>
        <div className="w-full h-full pt-16 px-16 pb-24">
          <div className="w-full h-full md:w-[330px] flex flex-col justify-between ml-auto p-5 rounded-2xl overflow-y-auto bg-white">
            <div className="flex flex-col items-center mt-10">
              <AppLogo width={38} height={38} fill="#FF7E1D" />
              <h1 className="text-[28px] leading-[45px] font-semibold mt-4 text-center">
                LENSE AI
              </h1>
              <p className="mt-2 text-center text-sm leading-7">
                New to Lense AI?{" "}
                <Link to="/register" className="underline text-orange-500">
                  Sign up for free
                </Link>
              </p>
            </div>
            {/* <button className="w-full flex items-center justify-center py-3.5 font-medium mt-16 rounded-md border borer-[#CDD5DD] pointer-events-none">
              <img
                src={GoogleLogo}
                alt="Google Logo"
                width={23}
                height={23}
                className="mr-3.5"
              />{" "}
              Continue with Google
            </button> */}
            <form className="w-full mt-[46px]" onSubmit={_handleSubmit}>
              <div className="grid grid-cols-1 gap-y-5">
                <TextInput
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  autoComplete="email"
                  placeholder="Email address*"
                  onChange={_handleEmailChange}
                />
                <TextInput
                  id="password"
                  type="password"
                  name="password"
                  value={password}
                  autoComplete="organization"
                  placeholder="Password*"
                  onChange={_handlePasswordChange}
                />
                <Link
                  to="/forgot_password"
                  className="text-xs font-medium underline text-[#ACB6C0] pointer-events-none">
                  Forgot password?
                </Link>
                <Button
                  type="submit"
                  size="lg"
                  className="mt-6"
                  loading={loading}>
                  Log in
                </Button>
                {error && (
                  <ErrorMessage message={errorMessage(error.message)} />
                )}
              </div>
            </form>
          </div>
        </div>
      </AuthLayout>
    </div>
  );
};

/* Export
============================================================================= */
export default LoginScreen;
