import React, {
  useRef,
  useState,
  type FormEvent,
  type FocusEvent,
  type ChangeEvent,
  type KeyboardEvent,
  type ClipboardEvent,
} from "react";
import {Helmet} from "react-helmet";

import {Button, ErrorMessage} from "../../common";
import AuthLayout from "../components/AuthLayout";
import VerifyEmailImage from "../../assets/images/verify-email.svg";

import request from "../../util/request";
import {API_URL} from "../../config/secrets";
import {useAppDispatch, useAppSelector} from "../../util/hooks";

import {emailVerified, selectUser} from "../redux/authSlice";

/* =============================================================================
<VerifyEmailScreen />
============================================================================= */
const VerifyEmailScreen: React.FC = () => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const dispatch = useAppDispatch();

  const [otp, setOtp] = useState(Array(4).fill(""));
  const [verifying, setVerifying] = useState(false);
  const [resending, setResending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const user = useAppSelector(selectUser, (a, b) => a?.email === b?.email);

  const email = user?.email || "";

  const _handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      !/^[0-9]{1}$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "Tab" &&
      !e.metaKey
    ) {
      e.preventDefault();
    }

    if (e.key === "Delete" || e.key === "Backspace") {
      const index = inputRefs.current.indexOf(e.target as HTMLInputElement);

      if (index > 0) {
        setOtp(prevOtp => [
          ...prevOtp.slice(0, index - 1),
          "",
          ...prevOtp.slice(index),
        ]);

        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const _handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {target} = e;

    const index = inputRefs.current.indexOf(target);

    if (target.value) {
      setOtp(prevOtp => [
        ...prevOtp.slice(0, index),
        target.value,
        ...prevOtp.slice(index + 1),
      ]);

      if (index < otp.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const _handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const text = e.clipboardData.getData("text");

    if (!new RegExp(`^[0-9]{${otp.length}}$`).test(text)) {
      return;
    }

    const digits = text.split("");

    setOtp(digits);
  };

  const _handleResend = async () => {
    if (!resending) {
      setResending(true);

      try {
        setErrorMessage("");

        const {status} = await fetch(`${API_URL}/auth/resend-otp`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("@auth/token")}`,
          },
        });

        if (status === 304) {
          dispatch(emailVerified());
          return;
        }

        if (status >= 400) {
          throw new Error("Unable to send email, Please try again later");
        }
      } catch (e) {
        setErrorMessage(e?.message);
      }

      setResending(false);
    }
  };

  const _handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const otpStr = otp.join("");

    if (otpStr && !verifying) {
      setVerifying(true);

      try {
        setErrorMessage("");

        await request({
          url: "/auth/verify-otp",
          method: "POST",
          data: {
            otp: otpStr,
          },
        });

        dispatch(emailVerified());
      } catch (e) {
        setErrorMessage(e?.message);

        setVerifying(false);
      }
    }
  };

  return (
    <div id="login" className="w-full h-full">
      <Helmet>
        <title>Verify Email | Lense AI</title>
      </Helmet>
      <AuthLayout>
        <div className="w-full h-full flex items-center justify-center pt-16 px-16 pb-24 bg-[rgba(0,0,0,0.6)]">
          <div className="w-full md:w-[437px] px-11 pt-11 pb-10 rounded-2xl overflow-y-auto bg-white">
            <div className="flex flex-col items-center mt-2">
              <VerifyEmailImage width={52} height={52} />
              <h1 className="text-[20px] leading-[45px] font-semibold mt-4 text-center">
                Verify your email
              </h1>
              <p className="mt-2 text-center text-sm text-[#536471] leading-6">
                {`Please enter the 4-digit code sent to\n${email} to verify your email.`}
              </p>
            </div>
            <form className="w-full mt-9" onSubmit={_handleSubmit}>
              <div className="flex items-center justify-center gap-3">
                {otp.map((digit, index) => (
                  <input
                    ref={el => (inputRefs.current[index] = el)}
                    key={index}
                    type="text"
                    value={digit}
                    maxLength={1}
                    className="shadow-xs w-16 h-16 rounded-lg border border-stroke bg-white text-center text-xl font-medium text-gray-5 outline-none sm:text-3xl"
                    onChange={_handleChange}
                    onKeyDown={_handleKeyDown}
                    onFocus={_handleFocus}
                    onPaste={handlePaste}
                  />
                ))}
              </div>
              <Button
                type="submit"
                size="lg"
                className="w-full mt-9"
                loading={verifying}>
                Verify my email
              </Button>
              <button
                type="button"
                className="block mx-auto text-sm font-semibold mt-6"
                onClick={_handleResend}>
                {resending ? "Resending..." : "Resend code"}
              </button>
              {errorMessage && (
                <div className="mt-6">
                  <ErrorMessage message={errorMessage} />
                </div>
              )}
            </form>
          </div>
        </div>
      </AuthLayout>
    </div>
  );
};

/* Export
============================================================================= */
export default VerifyEmailScreen;
