import React from "react";

import AuthLayoutBackground from "./AuthLayoutBackground";

interface Props {
  children: React.ReactNode;
}

/* =============================================================================
<AuthLayout />
============================================================================= */
const AuthLayout: React.FC<Props> = ({children}) => {
  return (
    <div className="w-full h-full relative">
      <AuthLayoutBackground />
      <div style={styles.content} className="w-full h-full">
        {children}
      </div>
    </div>
  );
};

const styles = {
  content: {
    zIndex: 1,
  },
};

/* Export
============================================================================= */
export default AuthLayout;
