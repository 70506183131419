import React, {useCallback, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";
import {shallowEqual} from "react-redux";
import {ChevronRightIcon, ChevronDownIcon} from "@heroicons/react/24/outline";

import {useAppDispatch, useAppSelector} from "../../../util/hooks";
import {GET_FIELD_MAP_LAYERS} from "../../api/queries";

import {
  layerSelected,
  layerUnselected,
  selectLayer,
  selectMap,
} from "../../redux/fieldTimelineSlice";

/* =============================================================================
<FieldViewAnalysisType />
============================================================================= */
const FieldViewAnalysisType: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const mapId = useAppSelector(selectMap, shallowEqual);
  const layerId = useAppSelector(selectLayer, shallowEqual);

  const {data} = useQuery(GET_FIELD_MAP_LAYERS, {
    variables: {
      where: {
        highResMapId: {
          _eq: mapId,
        },
      },
    },
  });

  const layerOptions = useMemo(() => {
    const options: Array<{label: string; value: string}> = [];

    if (data?.high_res_map_layers.length) {
      data.high_res_map_layers.forEach(layer => {
        options.push({
          label: layer.name ?? "",
          value: `${layer.id}`,
        });
      });
    }

    return options;
  }, [data]);

  const selectedLayerOption = useMemo(
    () => layerOptions.find(option => option.value === layerId),
    [layerOptions, layerId],
  );

  const _handleIconClick = useCallback(() => {
    setIsOpen(prevState => !prevState);
  }, []);

  const _handleLayerChange = (value: string) => {
    if (value === layerId) {
      dispatch(layerUnselected());
    } else {
      dispatch(layerSelected(value));
    }
  };

  if (layerOptions.length) {
    return (
      <div className="mt-4 px-4 pt-5 pb-4 rounded-lg shadow">
        <div className="flex items-center justify-between">
          <h2 className="text-xs font-medium text-[#787694]">Analysis type</h2>
          <button type="button" onClick={_handleIconClick}>
            {isOpen ? (
              <ChevronDownIcon
                className="h-4 w-4 text-black"
                aria-hidden="true"
              />
            ) : (
              <ChevronRightIcon
                className="h-4 w-4 text-black"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
        {isOpen ? (
          <div className="mt-6">
            {layerOptions.map(option => (
              <button
                key={option.value}
                type="button"
                className="flex items-center mb-4"
                onClick={() => _handleLayerChange(option.value)}>
                <span className="w-3.5 h-3.5 flex justify-center items-center rounded-full border border-black">
                  {layerId === option.value && (
                    <span className="w-1.5 h-1.5 rounded-full bg-black" />
                  )}
                </span>
                <span className="ml-2 text-sm font-medium text-gray-900">
                  {option.label}
                </span>
              </button>
            ))}
          </div>
        ) : (
          <h2 className="mt-4 mb-1 text-sm font-medium">
            {selectedLayerOption?.label}
          </h2>
        )}
      </div>
    );
  }

  return null;
};

/* Export
============================================================================= */
export default FieldViewAnalysisType;
