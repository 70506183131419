import {gql} from "../../__generated__/gql";

export const REGISTER = gql(/* GraphQL */ `
  mutation Register($input: api_sign_up_user_input) {
    api_sign_up_user(input: $input) {
      token
      id
    }
  }
`);
