import React from "react";
import {useAppDispatch} from "../../../util/hooks";

import PlusIcon from "../../../assets/icons/edit-plus.svg";

import {addFarmSelected} from "../../redux/farmSlice";

/* =============================================================================
<AddFarmButton />
============================================================================= */
const AddFarmButton: React.FC = () => {
  const dispatch = useAppDispatch();

  const _handleClick = () => {
    dispatch(addFarmSelected());
  };

  return (
    <button
      type="button"
      className="h-9 flex items-center px-4 rounded-lg bg-white app-shadow"
      onClick={_handleClick}>
      <PlusIcon />
      <span className="ml-2 font-medium text-sm">Add Farm</span>
    </button>
  );
};

/* Export
============================================================================= */
export default AddFarmButton;
