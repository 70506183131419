import React from "react";

import InfoErrorIcon from "../assets/icons/info-error.svg";
import {cls} from "../util/functions";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  message: string;
  iconSize?: number;
  className?: React.HTMLAttributes<HTMLElement>["className"];
  iconClassName?: React.HTMLAttributes<HTMLElement>["className"];
}

/* =============================================================================
<ErrorMessage />
============================================================================= */
const ErrorMessage: React.FC<Props> = ({
  message,
  iconSize,
  className,
  iconClassName,
  ...rest
}) => {
  return (
    <div
      role="alert"
      className={cls(`
        ${classes.container}
        ${className}
      `)}
      {...rest}>
      <InfoErrorIcon
        width={iconSize}
        height={iconSize}
        className={cls(`
          ${classes.icon}
          ${iconClassName}
        `)}
      />{" "}
      {message}
    </div>
  );
};

const classes = {
  container: "flex p-4 text-sm text-red-800 rounded-lg bg-red-50",
  icon: "mr-2",
};

ErrorMessage.defaultProps = {
  iconSize: 20,
};

/* Export
============================================================================= */
export default ErrorMessage;
